var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"turismo5-container px-3 pb-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FacilitySelect',{model:{value:(_vm.facilityId),callback:function ($$v) {_vm.facilityId=$$v},expression:"facilityId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"font-weight-bold tourist-tax-pro-red text-uppercase"},[_vm._v(" flussi turistici - Ross1000 ")]),_c('MonthSelect',{model:{value:(_vm.turismo5Date),callback:function ($$v) {_vm.turismo5Date=$$v},expression:"turismo5Date"}}),(_vm.pendingMonths.length)?_c('div',{staticClass:"warning white--text pa-4 mb-8 rounded-lg"},[_vm._v(" Attenzione: sono presenti delle rettifiche nei seguenti mesi: "),_c('ul',{staticClass:"mb-4"},_vm._l((_vm.pendingMonths),function(pendingMonth){return _c('li',{key:pendingMonth},[_vm._v(" "+_vm._s(pendingMonth)+" ")])}),0),_vm._v(" È necessario fare un nuovo invio a Ross1000 per ogni mese segnalato per correggere i dati. ")]):_vm._e(),_c('CtaButton',{staticClass:"full-width mb-3",attrs:{"disabled":!_vm.turismo5Enabled},on:{"click":function($event){return _vm.sendIntegration('Turismo5', {
            year: _vm.turismo5Date.year,
            month: _vm.turismo5Date.month
          })}}},[_vm._v("Invia i flussi turistici di Ross1000")]),_c('CtaButton',{staticClass:"full-width",attrs:{"disabled":!_vm.provinceFlowsEnabled},on:{"click":function($event){return _vm.downloadExport('ProvinceTouristsFlows', {
            year: _vm.turismo5Date.year,
            month: _vm.turismo5Date.month
          })}}},[_vm._v("export flussi turistici provincia excel")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"font-weight-bold tourist-tax-pro-red text-uppercase"},[_vm._v(" imposta di soggiorno comune di bergamo ")]),_c('YearSelect',{model:{value:(_vm.municipalityYear),callback:function ($$v) {_vm.municipalityYear=$$v},expression:"municipalityYear"}}),_c('QuarterSelect',{model:{value:(_vm.municipalityQuarter),callback:function ($$v) {_vm.municipalityQuarter=$$v},expression:"municipalityQuarter"}}),_c('CtaButton',{staticClass:"full-width mb-3",attrs:{"disabled":!_vm.municipalityReportingEnabled},on:{"click":function($event){return _vm.sendIntegration('QuarterlyMunicipalityReporting', {
            year: _vm.municipalityYear,
            quarter: _vm.municipalityQuarter
          })}}},[_vm._v("Invia rendicontazione comune trimestrale")]),_c('CtaButton',{staticClass:"full-width mb-3",attrs:{"disabled":!_vm.municipalityReportingPdfEnabled},on:{"click":function($event){return _vm.$router.push({
            name: 'EditPdf',
            params: {
              facilityId: _vm.facilityId
            },
            query: {
              year: _vm.municipalityYear,
              quarter: _vm.municipalityQuarter
            }
          })}}},[_vm._v("rendicontazione comune trimestrale pdf")]),_c('p',{staticClass:"mt-4"},[_vm._v(" Per scaricare i modelli 21 relativi alle diverse annualità è necessario selezionare nel campo seleziona anno, l'anno relativo al modello 21 che si vuole scaricare e poi schiacciare il bottone sottostante \"Rendicontazione annuale - MOD.21\". ")]),_c('CtaButton',{staticClass:"full-width",attrs:{"disabled":!_vm.yearlyReportingEnabled},on:{"click":function($event){return _vm.downloadExport('YearlyMunicipalityReportingZip', {
            year: _vm.municipalityYear,
            quarter: _vm.municipalityQuarter
          })}}},[_vm._v("Rendicontazione annuale - MOD.21")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }