<template>
  <section class="turismo5-container px-3 pb-5">
    <v-row>
      <v-col cols="12">
        <FacilitySelect v-model="facilityId" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <h4 class="font-weight-bold tourist-tax-pro-red text-uppercase">
          flussi turistici - Ross1000
        </h4>

        <MonthSelect v-model="turismo5Date" />

        <div
          class="warning white--text pa-4 mb-8 rounded-lg"
          v-if="pendingMonths.length"
        >
          Attenzione: sono presenti delle rettifiche nei seguenti mesi:
          <ul class="mb-4">
            <li v-for="pendingMonth of pendingMonths" :key="pendingMonth">
              {{ pendingMonth }}
            </li>
          </ul>
          È necessario fare un nuovo invio a Ross1000 per ogni mese segnalato
          per correggere i dati.
        </div>

        <CtaButton
          class="full-width mb-3"
          :disabled="!turismo5Enabled"
          @click="
            sendIntegration('Turismo5', {
              year: turismo5Date.year,
              month: turismo5Date.month
            })
          "
          >Invia i flussi turistici di Ross1000</CtaButton
        >
        <CtaButton
          class="full-width"
          :disabled="!provinceFlowsEnabled"
          @click="
            downloadExport('ProvinceTouristsFlows', {
              year: turismo5Date.year,
              month: turismo5Date.month
            })
          "
          >export flussi turistici provincia excel</CtaButton
        >
      </v-col>
      <v-col cols="12" md="6">
        <h4 class="font-weight-bold tourist-tax-pro-red text-uppercase">
          imposta di soggiorno comune di bergamo
        </h4>

        <YearSelect v-model="municipalityYear" />

        <QuarterSelect v-model="municipalityQuarter" />

        <CtaButton
          class="full-width mb-3"
          :disabled="!municipalityReportingEnabled"
          @click="
            sendIntegration('QuarterlyMunicipalityReporting', {
              year: municipalityYear,
              quarter: municipalityQuarter
            })
          "
          >Invia rendicontazione comune trimestrale</CtaButton
        >
        <CtaButton
          class="full-width mb-3"
          :disabled="!municipalityReportingPdfEnabled"
          @click="
            $router.push({
              name: 'EditPdf',
              params: {
                facilityId: facilityId
              },
              query: {
                year: municipalityYear,
                quarter: municipalityQuarter
              }
            })
          "
          >rendicontazione comune trimestrale pdf</CtaButton
        >

        <p class="mt-4">
          Per scaricare i modelli 21 relativi alle diverse annualità è
          necessario selezionare nel campo seleziona anno, l'anno relativo al
          modello 21 che si vuole scaricare e poi schiacciare il bottone
          sottostante "Rendicontazione annuale - MOD.21".
        </p>

        <CtaButton
          class="full-width"
          :disabled="!yearlyReportingEnabled"
          @click="
            downloadExport('YearlyMunicipalityReportingZip', {
              year: municipalityYear,
              quarter: municipalityQuarter
            })
          "
          >Rendicontazione annuale - MOD.21</CtaButton
        >
      </v-col>
    </v-row>
  </section>
</template>

<script>
import moment from "moment";
import CtaButton from "@/components/CtaButton.vue";
import FacilitySelect from "@/components/FacilitySelect.vue";
import MonthSelect from "@/components/MonthSelect.vue";
import YearSelect from "@/components/YearSelect.vue";
import QuarterSelect from "@/components/QuarterSelect.vue";

import { downloadFile } from "../utils";

export default {
  components: {
    CtaButton,
    FacilitySelect,
    MonthSelect,
    YearSelect,
    QuarterSelect
  },
  data() {
    return {
      facilityId: this.$route.query.facility || "",

      turismo5Date: {
        month: Number(this.$route.query.month) || moment().month(),
        year: Number(this.$route.query.year) || moment().year()
      },

      municipalityYear: Number(this.$route.query.year) || moment().year(),
      municipalityQuarter: moment([
        Number(this.$route.query.year) || moment().year(),
        Number(this.$route.query.month) || moment().month(),
        1
      ]).quarter(),

      turismo5Enabled: false,
      provinceFlowsEnabled: false,
      municipalityReportingEnabled: false,
      municipalityReportingPdfEnabled: false,
      yearlyReportingEnabled: false,
      pendingMonths: []
    };
  },

  watch: {
    facilityId: {
      immediate: true,
      handler() {
        if (this.facilityId) {
          this.loadIntegrations();
          this.loadExports();
          this.getPendingMonths();
        }
      }
    }
  },

  created() {
    this.pollingInterval = setInterval(() => {
      if (this.facilityId) {
        this.getPendingMonths();
      }
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.pollingInterval);
  },

  methods: {
    async loadIntegrations() {
      this.municipalityReportingEnabled = false;
      this.turismo5Enabled = false;

      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/integrations`
        );

        this.municipalityReportingEnabled = (
          data.result.QuarterlyMunicipalityReporting || {}
        ).enabled;
        this.turismo5Enabled = (data.result.Turismo5 || {}).enabled;
      } catch (err) {
        console.log(err);
      }
    },

    async loadExports() {
      this.provinceFlowsEnabled = false;
      this.municipalityReportingPdfEnabled = false;
      this.yearlyReportingEnabled = false;

      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/exports`
        );

        this.provinceFlowsEnabled = !!data.result.ProvinceTouristsFlows;
        this.municipalityReportingPdfEnabled = !!data.result
          .QuarterlyMunicipalityReportingPdf;
        this.yearlyReportingEnabled = !!data.result
          .YearlyMunicipalityReportingZip;
      } catch (err) {
        console.log(err);
      }
    },

    async sendIntegration(integration, params) {
      try {
        await this.$axios.post(
          `/api/v1/facilities/${this.facilityId}/integrations/${integration}/send`,
          {
            ...params
          }
        );

        this.$root.alert(
          "La trasmissione dei dati è stata avviata, riceverai una mail con l'esito dell'operazione."
        );
      } catch (err) {
        this.parseErrorResponse(err).then(message => {
          const defaultMessage = "controlla i campi di selezione e riprova";
          this.$root.alert(
            `La trasmissione dei dati è fallita: ${message || defaultMessage}.`,
            "error"
          );
        });
      }
    },

    async downloadExport(exportName, params) {
      this.$root.alert("Download del file in corso...", "progress");

      try {
        const { data, headers } = await this.$axios.post(
          `/api/v1/facilities/${this.facilityId}/exports/${exportName}/download`,
          {
            ...params
          },
          {
            responseType: "blob"
          }
        );

        const [, filename] = headers["content-disposition"].split("=");
        downloadFile(data, filename);
        this.$root.hideAlert();
      } catch (err) {
        this.parseErrorResponse(err, true).then(message => {
          const defaultMessage = "controlla i campi di selezione e riprova";
          this.$root.alert(
            `Il download del file è fallito: ${message || defaultMessage}.`,
            "error"
          );
        });
      }
    },

    parseErrorResponse(err, blob = false) {
      return new Promise(resolve => {
        let message = "";
        if (err.response) {
          const data = err.response.data;
          if (blob) {
            data.text().then(text => {
              console.log(text);
              try {
                const errorData = JSON.parse(text);
                if (errorData.errors && errorData.errors.length) {
                  message = errorData.errors[0].message;
                }
                resolve(message);
              } catch {
                resolve(message);
              }
            });
          } else {
            if (data.errors && data.errors.length) {
              message = data.errors[0].message;
            }
            resolve(message);
          }
        } else {
          resolve(message);
        }
      });
    },

    async getPendingMonths() {
      if (!this.facilityId) {
        return;
      }

      try {
        const { data } = await this.$axios.get(
          `/api/v1/facilities/${this.facilityId}/pending-months`
        );
        this.pendingMonths = data.result;
      } catch (err) {
        this.pendingMonths = [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.turismo5-container {
  background-color: #f1f1f1;

  .full-width {
    width: 100%;
  }
}
</style>
